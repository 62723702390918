import React from 'react'
import { Provider } from 'react-redux'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import createStore from './src/state'

import { ServerLocation } from '@reach/router'
export const store = createStore()

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, pathname }) => {
  const persistor = persistStore(store)
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  //const store = createStore()
  if (typeof window !== 'undefined') {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {element}{' '}
        </PersistGate>
      </Provider>
    )
  }

  return (
    <ServerLocation url={pathname}>
      <Provider store={store}>{element}</Provider>
    </ServerLocation>
  )
}
