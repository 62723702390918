export enum ActionType {
  UPDATE_PLAN_HASH = 'updatePlanHash',
  UPDATE_COUPON_HASH = 'updateCouponHash',
  UPDATE_PRODUCT_HASH = 'updateProductHash',
  SET_HASH_LOADED = 'setHashLoaded',
  RESET = 'resetHashes',
  RESET_SINGLE_HASH = 'resetSingleHash'
}

interface UpdatePlanHashAction {
  type: ActionType.UPDATE_PLAN_HASH
  payload: string
}

interface UpdateCouponHashAction {
  type: ActionType.UPDATE_COUPON_HASH
  payload: string
}

interface UpdateProductHashAction {
  type: ActionType.UPDATE_PRODUCT_HASH
  payload: string
}
interface SetHashLoadedAction {
  type: ActionType.SET_HASH_LOADED
  payload: boolean
}

interface ResetAction {
  type: ActionType.RESET
}

interface ResetSingleAction {
  type: ActionType.RESET_SINGLE_HASH
  payload: string
}

export type Action =
  | UpdatePlanHashAction
  | UpdateCouponHashAction
  | UpdateProductHashAction
  | SetHashLoadedAction
  | ResetAction
  | ResetSingleAction
