import { Action, ActionType } from '../types/hashType'

interface StateType {
  planHash: string
  couponHash: string
  productListHash: string
  hashLoaded: boolean
}

const initialState: StateType = {
  planHash: '',
  couponHash: '',
  productListHash: '',
  hashLoaded: false
}

const reducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_PLAN_HASH:
      return { ...state, planHash: action.payload }
    case ActionType.UPDATE_COUPON_HASH:
      return { ...state, couponHash: action.payload }
    case ActionType.UPDATE_PRODUCT_HASH:
      return { ...state, productListHash: action.payload }
    case ActionType.SET_HASH_LOADED:
      return { ...state, hashLoaded: action.payload }
    case ActionType.RESET:
      return { ...initialState }
    case ActionType.RESET_SINGLE_HASH: {
      const fieldToReset = action.payload
      return { ...state, [fieldToReset]: '' }
    }
    default:
      return state
  }
}

export default reducer
