import { Action, ActionType, OrderSummaryType, ShippingType } from '../../../types/shippingType'

interface StateType {
  shipping: ShippingType
  loading: boolean
  error: boolean
  changed: boolean
  orderSummary: OrderSummaryType
}

const initialState: StateType = {
  shipping: {
    customer: {
      name: '',
      surname: '',
      email: '',
      marketingOptIn: false,
      telephone: '',
      smsOptIn: false,
      password: ''
    },
    address: {
      shipping: {
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: ''
      },
      billing: {
        address1: '',
        address2: '',
        city: '',
        county: '',
        postcode: ''
      }
    },
    coupon: '',
    notes: '',
    payment: '',
    recipes: [],
    addons: [],
    deliveryDate: '',
    plan: {
      id: '',
      type: '',
      level: 0,
      size: '',
      grams: ''
    }
  },
  orderSummary: {
    recipes: [],
    addons: [],
    deliveryDate: ''
  },
  loading: false,
  error: false,
  changed: false,
  errorMessage: null,
  total: 0
}

const ShippingReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SUBMIT_ACCOUNT_SHIPPING_PENDING:
      return { ...state, loading: true }
    case ActionType.SUBMIT_ACCOUNT_SHIPPING_SUCCESS:
      return {
        ...state,
        loading: false,
        changed: action.payload.changed,
        shipping: action.payload.shippingConfirm,
        errorMessage: '',
        total: action.payload.total
      }
    case ActionType.ADD_ACCOUNT_ORDER_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        orderSummary: action.payload.orderSummary
      }
    case ActionType.SUBMIT_ACCOUNT_SHIPPING_FAIL:
      return { ...state, loading: false, error: true, errorMessage: action.payload.errorMessage }
    case ActionType.SAVE_ACCOUNT_SHIPPING_FORM: {
      return {
        ...state,
        shipping: action.payload,
        errorMessage: ''
      }
    }
    case ActionType.RESET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: null
      }
    case ActionType.RESET:
      return { ...initialState }
    default:
      return state
  }
}

export default ShippingReducer
