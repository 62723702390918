import { PaymentMethod, SetupIntent } from '@stripe/stripe-js'
import { PlanType, RecipeType } from './planType'

export enum ActionType {
  SUBMIT_SHIPPING_PENDING = 'submitShippingPending',
  SUBMIT_SHIPPING_SUCCESS = 'submitShippingSuccess',
  SUBMIT_SHIPPING_FAIL = 'submitShippingFail',
  SAVE_SHIPPING_FORM = 'saveShippingForm',
  ADD_ORDER_SUMMARY_SUCCESS = 'addOrderDeliverySummarySuccess',
  SUBMIT_ACCOUNT_SHIPPING_PENDING = 'submitAccountShippingPending',
  SUBMIT_ACCOUNT_SHIPPING_SUCCESS = 'submitAccountShippingSuccess',
  SUBMIT_ACCOUNT_SHIPPING_FAIL = 'submitAccountShippingFail',
  SAVE_ACCOUNT_SHIPPING_FORM = 'saveAccountShippingForm',
  ADD_ACCOUNT_ORDER_SUMMARY_SUCCESS = 'addAccountOrderDeliverySummarySuccess',
  REQUIRES_3D_SECURE = 'requires3dSecureCheckout',
  VALIDATE_POSTCODE_PENDING = 'validatePostcodePending',
  VALIDATE_POSTCODE_SUCCESS = 'validatePostcodeSuccess',
  VALIDATE_POSTCODE_FAIL = 'validatePostcodeFail',
  RESET_LOADING_STATUS = 'resetShippingLoadingStatus',
  RESET_ERROR_MESSAGE = 'resetShippingErrorMessage',
  RESET = 'resetShipping'
}

export interface ShippingType {
  customer: {
    name: string
    surname: string
    email: string
    marketingOptIn: boolean
    telephone: string
    smsOptIn: boolean
    password: string
    total?: string
    marketingCookieConsent?: boolean
  }
  address: {
    shipping: {
      address1: string
      address2: string
      city: string
      county: string
      postcode: string
    }
    billing: {
      name: string
      surname: string
      address1: string
      address2: string
      city: string
      county: string
      postcode: string
    }
  }
  coupon: string
  notes: string
  payment: PaymentMethod | undefined
  setupIntent: SetupIntent | undefined
  recipes: RecipeType[]
  addons: RecipeType[]
  deliveryDate: string
  plan: {
    id: string
    type: string
    level: number
    size: string
    grams: string
  }
  dogs?: Object
}

interface SubmitShippingPendingAction {
  type: ActionType.SUBMIT_SHIPPING_PENDING
}

interface SubmitShippingSuccessAction {
  type: ActionType.SUBMIT_SHIPPING_SUCCESS
  payload: {
    changed: boolean
    shippingConfirm: ShippingType
  }
}

interface SaveShippingFormAction {
  type: ActionType.SAVE_SHIPPING_FORM
  payload: ShippingType
}

interface SubmitShippingFailureAction {
  type: ActionType.SUBMIT_SHIPPING_FAIL
  payload: {
    errorMessage: {
      e: number
      message: string
      action: string
      url: string | null
    }
  }
}

interface SubmitAccountShippingPendingAction {
  type: ActionType.SUBMIT_ACCOUNT_SHIPPING_PENDING
}

interface SubmitAccountShippingSuccessAction {
  type: ActionType.SUBMIT_ACCOUNT_SHIPPING_SUCCESS
  payload: {
    changed: boolean
    shippingConfirm: ShippingType
    total: number
  }
}

interface SaveAccountShippingFormAction {
  type: ActionType.SAVE_ACCOUNT_SHIPPING_FORM
  payload: ShippingType
}

interface SubmitAccountShippingFailureAction {
  type: ActionType.SUBMIT_ACCOUNT_SHIPPING_FAIL
  payload: {
    errorMessage: {
      e: number
      message: string
      action: string
      url: string | null
    }
  }
}

interface Requires3dSecureAction {
  type: ActionType.REQUIRES_3D_SECURE
  payload: any
}

export interface RecipesAddonsType {
  id: string
  quantity: number
  price: number
}

export interface OrderSummaryType {
  recipes: Array<RecipesAddonsType>
  addons: Array<RecipesAddonsType>
  deliveryDate: string
  subscriptionId: string
  customerId: string
}

interface AddOrderSummarySuccessAction {
  type: ActionType.ADD_ORDER_SUMMARY_SUCCESS
  payload: {
    orderSummary: OrderSummaryType
  }
}

interface AddAccountOrderSummarySuccessAction {
  type: ActionType.ADD_ACCOUNT_ORDER_SUMMARY_SUCCESS
  payload: {
    orderSummary: OrderSummaryType
  }
}

interface ValidatePostcodePendingAction {
  type: ActionType.VALIDATE_POSTCODE_PENDING
}

interface ValidatePostcodeSuccessAction {
  type: ActionType.VALIDATE_POSTCODE_SUCCESS
}

interface ValidatePostcodeFailAction {
  type: ActionType.VALIDATE_POSTCODE_FAIL
  payload: {
    errorMessage: {
      e: number
      message: string
      action: string
      url: string | null
    }
  }
}

interface ResetLoadingStatusAction {
  type: ActionType.RESET_LOADING_STATUS
}

interface ResetErrorMessageAction {
  type: ActionType.RESET_ERROR_MESSAGE
}

interface ResetAction {
  type: ActionType.RESET
}

export type Action =
  | SubmitShippingPendingAction
  | SubmitShippingSuccessAction
  | SubmitShippingFailureAction
  | SaveShippingFormAction
  | AddOrderSummarySuccessAction
  | AddAccountOrderSummarySuccessAction
  | SubmitAccountShippingPendingAction
  | SubmitAccountShippingSuccessAction
  | SubmitAccountShippingFailureAction
  | SaveAccountShippingFormAction
  | Requires3dSecureAction
  | ValidatePostcodePendingAction
  | ValidatePostcodeSuccessAction
  | ValidatePostcodeFailAction
  | ResetLoadingStatusAction
  | ResetErrorMessageAction
  | ResetAction
